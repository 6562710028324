import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "gatsby"
import truncate from "lodash/truncate"
import upperFirst from "lodash/upperFirst"

import Image from "../image/image"
import generatePath from "../../helpers/generatePath"
import FindOutMore from "../findOutMore/findOutMore"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./industriesListing.module.scss"

const IndustriesCard = props => {
  const { title, description, image, page, listingStyle } = props
  const url = generatePath(page)
  const { t } = useTranslation()
  const cutDesc = truncate(description, { length: 125, omission: " ...", separator: " " })

  return (
    <Link className={classNames("container rounded", styles.industriesCard, styles[listingStyle])} to={`/${url}`}>
      <div className={styles.imageContainer}>
        <Image image={image} alt={title} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className="body3 darkgrey">{cutDesc}</div>
      <FindOutMore className={styles.button} label={upperFirst(t("find out more"))} />
    </Link>
  )
}

IndustriesCard.propTypes = {
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  page: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  listingStyle: PropTypes.oneOf(["work", "edu"]),
}

IndustriesCard.defaultProps = {
  listingStyle: "work",
}

export default IndustriesCard
