// extracted by mini-css-extract-plugin
export var sbShowMain = "industriesListing-module--sb-show-main--3tCd8";
export var industriesListing = "industriesListing-module--industriesListing--35-kp";
export var border = "industriesListing-module--border--3vnMZ";
export var body = "industriesListing-module--body--2iSZx";
export var twoItems = "industriesListing-module--twoItems--2hMMD";
export var plug = "industriesListing-module--plug--1F1kc";
export var item = "industriesListing-module--item--2bLXo";
export var rocketIcon = "industriesListing-module--rocketIcon--2kJEn";
export var rocketIconLast = "industriesListing-module--rocketIconLast--RelHl";
export var edu = "industriesListing-module--edu--35r9M";
export var industriesCard = "industriesListing-module--industriesCard--3lPH7";
export var imageContainer = "industriesListing-module--imageContainer--2Yq0n";
export var title = "industriesListing-module--title--3nmnP";
export var button = "industriesListing-module--button--2X04X";