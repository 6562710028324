import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { AiOutlineRocket } from "react-icons/ai"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import IndustriesCard from "./industriesCard"
import Section from "../section/section"

import * as styles from "./industriesListing.module.scss"

const RocketIcon = ({ className }) => (
  <div className={classNames("icon xxs", className)}>
    <AiOutlineRocket size={28} color="lightgrey" />
  </div>
)

const ItemWithIcons = ({ className, children }) => (
  <div className={classNames(styles.item, className)}>
    {children}
    <RocketIcon className={styles.rocketIcon} />
    <RocketIcon className={styles.rocketIconLast} />
  </div>
)

const Plug = ({ condition }) =>
  !!condition && (
    <ItemWithIcons>
      <div className={styles.plug} />
    </ItemWithIcons>
  )

const IndustriesListing = props => {
  const { title, data, listingStyle } = props
  const breakpoints = useBreakpoint()
  const isThreeColumns = !breakpoints.industriesMax
  const isTwoColumns = breakpoints.industriesMax && breakpoints.industriesMin
  const isOneElRemain = data.length % 3 === 1
  const isThreeDivisible = !(data.length % 3)
  const isOdd = data.length % 2
  const hasTwo = data.length === 2

  const renderCard = (item, idx) => {
    const { content, industryListingPreview } = item?.tabs || {}
    const isLast = idx === data.length - 1
    const props = {
      title: content?.title,
      image: industryListingPreview?.logo.asset,
      description: industryListingPreview?.excerpt,
      page: item,
      listingStyle,
    }

    return (
      <React.Fragment key={item._id}>
        <Plug condition={isLast && isOneElRemain && isThreeColumns} />
        <ItemWithIcons>
          <IndustriesCard {...props} />
        </ItemWithIcons>
        <Plug condition={!hasTwo && isLast && ((!isThreeDivisible && isThreeColumns) || (isOdd && isTwoColumns))} />
      </React.Fragment>
    )
  }

  return (
    <Section className={classNames(styles.industriesListing, styles[listingStyle])} background="grey-gradient">
      <div className={styles.border} />
      <h2 className={`text-align-center ${listingStyle}-gradient mb-m`}>{title}</h2>
      <div className={classNames(styles.body, hasTwo && styles.twoItems)}>{data.map(renderCard)}</div>
    </Section>
  )
}

IndustriesListing.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  listingStyle: PropTypes.oneOf(["work", "edu"]),
}

IndustriesListing.defaultProps = {
  data: [],
  title: "",
  listingStyle: "work",
}

export default IndustriesListing
