import React from "react"
import { Helmet } from "react-helmet-async"
import { graphql } from "gatsby"
import upperFirst from "lodash/upperFirst"

import Layout from "../components/layout"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import HeroSelector from "../components/hero/heroSelector"
import Seo from "../components/seo/seo"
import IndustriesListing from "../components/industries/industriesListing"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"

import { useTranslation } from "../helpers/useTranslation"
import { getHreflang, getPagePath } from "../helpers/getHreflang"
import { findAvailableModule } from "../helpers/findAvailableModule"

function IndustryListing({ data, location }) {
  const { t } = useTranslation()
  const { content, seo } = data.sanityIndustryListing.tabs
  const pagePath = getPagePath()
  const hreflangAbbr = getHreflang()
  const link = content.market === "en-us" ? "industries" : `${content.market}/industries`
  const availableFaqInPB = findAvailableModule(content._rawPageBuilder, "faq", "faqNoImage")
  const availableFaqInPB2 = findAvailableModule(content._rawPageBuilder2, "faq", "faqNoImage")
  const availableFaq = availableFaqInPB || availableFaqInPB2

  return (
    <Layout theme={content.theme}>
      <Helmet>
        <link rel="alternate" href={pagePath} hreflang={hreflangAbbr} />
        <link
          rel="alternate"
          href={
            content.market === "es"
              ? pagePath.replace("/es/industries", "/au/industries")
              : content.market === "en-au"
              ? pagePath.replace("/au/industries", "/es/industries")
              : content.market === "en-us"
              ? pagePath.replace("/industries", "/au/industries")
              : content.market === "pt"
              ? pagePath.replace("/pt/industries", "/industries")
              : pagePath
          }
          hreflang={
            content.market === "es"
              ? "en-au"
              : content.market === "en-au"
              ? "es"
              : content.market === "en-us"
              ? "en-au"
              : content.market === "pt"
              ? "en"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            content.market === "es"
              ? pagePath.replace("/es/industries", "/industries")
              : content.market === "en-au"
              ? pagePath.replace("/au/industries", "/industries")
              : content.market === "en-us"
              ? pagePath.replace("/industries", "/es/industries")
              : content.market === "pt"
              ? pagePath.replace("/pt/industries", "/es/industries")
              : pagePath
          }
          hreflang={
            content.market === "es"
              ? "en"
              : content.market === "en-au"
              ? "en"
              : content.market === "en-us"
              ? "es"
              : content.market === "pt"
              ? "es"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            content.market === "es"
              ? pagePath.replace("/es/industries", "/pt/industries")
              : content.market === "en-au"
              ? pagePath.replace("/au/industries", "/pt/industries")
              : content.market === "en-us"
              ? pagePath.replace("/industries", "/pt/industries")
              : content.market === "pt"
              ? pagePath.replace("/pt/industries", "/au/industries")
              : pagePath
          }
          hreflang={
            content.market === "es"
              ? "pt"
              : content.market === "en-au"
              ? "pt"
              : content.market === "en-us"
              ? "pt"
              : content.market === "pt"
              ? "en-au"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            content.market === "es"
              ? pagePath.replace("/es/industries", "/industries")
              : content.market === "en-au"
              ? pagePath.replace("/au/industries", "/industries")
              : content.market === "pt"
              ? pagePath.replace("/pt/industries", "/industries")
              : pagePath
          }
          hreflang="x-default"
        />
      </Helmet>
      <Seo
        title={content.title}
        breadcrumbs={[
          {
            link,
            title: "Industries",
          },
        ]}
        faq={availableFaq}
        path={location.pathname}
        {...seo}
      />
      <Breadcrumbs homePageLabel={t("industries")} />
      {content._rawHero && <HeroSelector heroSelector={[content._rawHero]} />}
      {content._rawWork && (
        <div id="business">
          <IndustriesListing data={content._rawWork} title={upperFirst(t("business we work with"))} />
        </div>
      )}
      {content._rawPageBuilder && <PageBuilder pageBuilder={content._rawPageBuilder} />}
      {content._rawEdu && (
        <div id="education">
          <IndustriesListing
            data={content._rawEdu}
            listingStyle="edu"
            title={upperFirst(t("education we work with"))}
          />
        </div>
      )}
      {content._rawPageBuilder2 && <PageBuilder pageBuilder={content._rawPageBuilder2} />}
    </Layout>
  )
}

export const query = graphql`
  query ($market: String!) {
    sanityIndustryListing(tabs: { content: { market: { eq: $market } } }) {
      tabs {
        content {
          _rawHero
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
          _rawPageBuilder2(resolveReferences: { maxDepth: 10 })
          _rawEdu(resolveReferences: { maxDepth: 10 })
          _rawWork(resolveReferences: { maxDepth: 10 })
          market
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
  }
`
export default IndustryListing
